import { useEffect, useState } from 'react'
import isServerSide from '../../../../modules/is-server-side'
import consoleLogger from '../../../../modules/console-logger'
import isStolApp from '../../../../modules/is-stolapp'

const useUserCustomFields = (dependency) => {
  const [userData, setUserData] = useState()
  const [activateListener, setActivateListener] = useState(false)
  const [consoleLog] = consoleLogger('UseUserCustomFields')

  useEffect(() => {

    if (!isServerSide() && !isStolApp('iphone|ipad') && window.tp && window.tp.pianoId && window.tp.pianoId.loadExtendedUser) {
      try {
        if (!userData && !activateListener) {
          window.tp.pianoId.loadExtendedUser({
            extendedUserLoaded: function(data) {
              if (data) {
                consoleLog('state', userData, 'UseUserData')
                consoleLog('extendedUserLoaded data', data, 'UseUserData')
                setUserData(data)
              }
            },
            formName: 'MyAccountFields',
          })
          setActivateListener(true)
        }
      } catch (e) {
        consoleLog('error', e, "UseUserData")
      }
    }
  }, [dependency])

  return userData
}

export default useUserCustomFields
